import classNames from 'classnames'
import type { ReactNode } from 'react'

interface PageWrapperProps {
  children: ReactNode
  size?: 'skinny' | 'fat' | 'custom'
  className?: string
  innerClassName?: string
}
const PageWrapper = ({ children, size, className, innerClassName }: PageWrapperProps) => (
  <div className={classNames(className)}>
    <div
      className={classNames(
        'mx-auto w-full p-4 pb-24 sm:pt-8',
        {
          'max-w-lg': size === 'skinny',
          'max-w-3xl': size === 'fat'
        },
        innerClassName
      )}>
      {children}
    </div>
  </div>
)

export default PageWrapper
